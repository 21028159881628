import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { getClasses } from "@washingtonpost/front-end-utils";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";

/* Utilities */
import { getSeparator } from "./Sigline.helpers.js";
import { ConditionalWrapper } from "../_utilities/components";

const Sigline = ({
  altByline,
  prefix,
  authors,
  timestamp,
  diffInMins = 10000 * 365 * 24 * 60,
  recencyThreshold = 30,
  timeToRead
}) => {
  const { EditableProperty } = useAssemblerContext();

  const showAltByline = altByline && altByline.text;
  const showAuthors = !showAltByline && authors && authors.length;
  const showByline = showAltByline || showAuthors;

  if (!showByline && !timestamp) {
    return null;
  }

  const tsColor =
    Math.floor(Math.abs(diffInMins)) > recencyThreshold || recencyThreshold <= 0
      ? "gray-dark"
      : "red";

  const bltClasses = "blt blt-dot-large blt-gray-dark blt-inbetween";

  const altBylineText = altByline?.text?.trim();
  const tsClasses = getClasses(`timestamp ${tsColor}`, {
    [`${bltClasses}`]: showByline
  });

  // const attrs = showAltByline ? altByline.editableContentInfo.attrs : {};

  // TODO: In future, clean up or implement for real
  timeToRead = undefined;
  const ttrClasses = getClasses(`timestamp`, {
    [`${bltClasses}`]: showByline || timestamp
  });

  return (
    <div className={"byline gray-dark font-xxxxs pb-xs"}>
      {showAltByline && altBylineText && (
        <EditableProperty
          tag="span"
          // we dont want editableProp to grab from root customFields,
          // we've overridden them with our values
          path={!altBylineText ? "" : "altByline"}
          value={altBylineText}
          menuItems={[]}
        />
      )}
      {showAuthors &&
        authors.map((author, index) => {
          const { name, url, org } = author;

          // Very rare edge case typically caused
          // By a referent not properly inflating
          if (!name && !url && !org) return null;

          return (
            <Fragment key={index}>
              {index === 0 ? prefix : ``}

              <ConditionalWrapper
                condition={url}
                wrapper={(children) => <a href={url}>{children}</a>}
              >
                {name || org}
              </ConditionalWrapper>
              {getSeparator({ items: authors, i: index })}
            </Fragment>
          );
        })}
      {timestamp && (
        <span suppressHydrationWarning className={tsClasses}>
          {timestamp}
        </span>
      )}
      {timeToRead && <span className={ttrClasses}>{timeToRead}</span>}
    </div>
  );
};

const authorShape = PropTypes.shape({
  name: PropTypes.string,
  org: PropTypes.string,
  url: PropTypes.string
});

Sigline.propTypes = {
  altByline: PropTypes.shape({
    text: PropTypes.string
  }),
  authors: PropTypes.arrayOf(authorShape),
  diffInMins: PropTypes.number,
  prefix: PropTypes.string,
  recencyThreshold: PropTypes.number,
  showTimestamp: PropTypes.bool,
  timestamp: PropTypes.string,
  timeToRead: PropTypes.string
};

export default Sigline;
