import React from "react";
import PropTypes from "prop-types";
import fetchLayoutFromArtPosition from "./_children/artPosition";
import { Markup, getJsonFromLayout } from "./_children/helpers";
import { allTransformsOrder } from "./_utilities/helpers";

const StoryCard = ({
  overrides,
  className = "",
  style = {},
  attrs = {},
  isAdmin,
  ...props
}) => {
  let { layout } = fetchLayoutFromArtPosition(overrides);

  // Critical piece -- this modifies the layout so the components are ordered the way we want.
  layout = allTransformsOrder({ layout, overrides });

  const json = getJsonFromLayout({
    layout,
    overrides,
    isAdmin,
    ...props
  });

  return Markup({ json, overrides, className, style, attrs });
};

StoryCard.propTypes = {
  overrides: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  attrs: PropTypes.object,
  isAdmin: PropTypes.bool
};

export default React.memo(StoryCard);
