import React, { Fragment } from "react";
import PropTypes from "prop-types";

/* Utilities */
import { useAppContext } from "fusion-context";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";

import getBlurbToolbarItems from "./Blurb.toolbar";

export const blurbClasses =
  "pb-xs font-size-blurb lh-fronts-tiny font-light gray-dark";

const Blurb = (props) => {
  const { text = "", url = "", path = "" } = props;
  const { EditableProperty } = useAssemblerContext();
  if (!text || !text.trim()) return null;

  const toolbarItems = getBlurbToolbarItems(props);

  const blurb = (
    <EditableProperty
      tag="span"
      path={path}
      value={text}
      menuItems={toolbarItems}
    />
  );

  return (
    <div className={`${blurbClasses}`} style={{ fontSize: "0.9375rem" }}>
      {url ? <a href={url}>{blurb}</a> : blurb}
    </div>
  );
};

export const MultiBlurb = ({ items, link, ...rest }) => {
  const { isAdmin } = useAppContext();
  // NOTE: Handling extra blurbs first, just to keep counting simpler
  if (!items || items.length < 1) return null;

  return (
    // NOTE: When the feature layout is complete, this should go away
    <Fragment>
      {items.length > 0 &&
        items.map((blurbObj, i) => {
          const { text, path } = blurbObj;
          return (
            <Blurb
              key={i}
              path={path}
              text={text}
              url={link && link.url ? link.url : ""}
              {...rest}
              isAdmin={isAdmin}
            />
          );
        })}
    </Fragment>
  );
};

const blurbObj = {
  text: PropTypes.string
};
const linkObj = {
  url: PropTypes.url,
  type: PropTypes.oneOf(["article", "gallery", "graphic", "video", "web"]),
  last_modified: PropTypes.string
};

MultiBlurb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(blurbObj)),
  link: PropTypes.shape(linkObj)
};

Blurb.propTypes = {
  text: PropTypes.string,
  url: PropTypes.url,
  path: PropTypes.string,
  setEntity: PropTypes.func,
  value: PropTypes.string
};

export default Blurb;
